<template>
  <register-container>
    <template slot="header"> Inform </template>
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
        <p class="text-h6 mb-5">How would you like your visit?</p>
        <!-- Communication Preference Buttons-->
        <div class="d-flex mb-5 mb-sm-7 mb-md-10 justify-space-between">
          <hover-button
            card="true"
            @click="communicationPreference = 'phone'"
            :selected="communicationPreference === 'phone'"
          >
            Phone
          </hover-button>

          <hover-button
            card="true"
            :disabled="true"
            @click="communicationPreference = 'video'"
            :selected="communicationPreference === 'video'"
          >
            Video
          </hover-button>
          <hover-button
            card="true"
            :disabled="true"
            @click="communicationPreference = 'inPerson'"
            :selected="communicationPreference === 'inPerson'"
          >
            In Person
          </hover-button>
        </div>
        <!-- Reason(s) for visit field-->
        <div class="d-flex flex-column">
          <p class="text-h6 mb-5">Reason for visit & Current Medications</p>
          <div class="d-flex mb-5">
            <v-textarea
              v-model="appointmentDetails.reasonForVisit"
              hide-details
              height="100px"
              :class="tenantUrl + '-theme-textarea'"
              outlined
              placeholder="..."
            >
            </v-textarea>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex flex-column justify-space-between pa-0 pl-sm-2">
        <!-- Annual Checkup -->
        <div>
          <p class="text-h6 mb-5">Have you had your annual checkup?</p>
          <div class="d-flex mb-5 justify-space-between">
            <hover-button @click="annualCheckup = true" :selected="annualCheckup">
              Yes
            </hover-button>

            <hover-button @click="annualCheckup = false" :selected="annualCheckup === false">
              No
            </hover-button>
          </div>
        </div>
        <!-- Specialist Option -->
        <div>
          <p class="text-h6 mb-5">Would you like to see a specialist?</p>
          <div class="d-flex mb-5 justify-space-between">
            <hover-button @click="seeSpecialist = true" :selected="seeSpecialist">
              Yes
            </hover-button>
            <hover-button @click="seeSpecialist = false" :selected="seeSpecialist === false">
              No
            </hover-button>
          </div>
        </div>
        <!-- Doctor's Note -->
        <div class="mb-0 justify-space-between">
          <p class="text-h6 mb-5">Do you need a doctor's note?*</p>
          <div class="d-flex mb-4 justify-space-between">
            <hover-button @click="docNoteRequired = true" :selected="docNoteRequired">
              Yes
            </hover-button>
            <hover-button @click="docNoteRequired = false" :selected="docNoteRequired === false">
              No
            </hover-button>
          </div>
        </div>
      </v-col>
    </v-row>
  </register-container>
</template>
<style scoped>
.chat-btn-disabled {
  border: 1px solid lightslategray !important;
  border-radius: 25px !important;
}
.info-btn {
  border: 1px solid var(--v-accent-base) !important;
  border-radius: 25px !important;
}
.card-btn {
  border: 1px solid var(--v-secondary-base);
  /* max-width: 120px !important; */
  word-wrap: normal;
  width: 30% !important;
}
.reason-btn {
  border: 1px solid var(--v-secondary-base);
  background-color: white !important;
  border-radius: 8px !important;
}
</style>

<script>
import { required } from 'vuelidate/lib/validators';
import { VUELIDATE_ERRORS } from '../constants/validation';
import RegisterContainer from '../components/RegisterContainer.vue';
import HoverButton from '../components/HoverButton.vue';

export default {
  name: 'RegisterInformAbc',
  mixins: [VUELIDATE_ERRORS],
  components: { RegisterContainer, HoverButton },
  data() {
    return {
      hover: null,
      reasonHover: null,
      checkupHover: null,
      specialistHover: null,
      docNoteHover: null,
      communicationPreference: null,
      docNoteRequired: null,
      annualCheckup: null,
      seeSpecialist: null,
      appointmentDetails: {
        reasonForVisit: null,
      },
    };
  },
  validations() {
    return {
      appointmentDetails: {
        reasonForVisit: {
          required,
        },
      },
    };
  },
  computed: {
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
    chatDisabled() {
      return this.$store.state.registration.appointmentPreference !== 'none';
    },
    completed() {
      const { reasonForVisit } = this.appointmentDetails;

      if (
        !this.$v.$invalid &&
        reasonForVisit &&
        this.communicationPreference &&
        this.docNoteRequired !== null &&
        this.annualCheckup !== null &&
        this.seeSpecialist !== null
      ) {
        return true;
      }
      return false;
    },
    appointmentDetailsNotes() {
      const appDetails = [
        `Contact By: ${this.communicationPreference}`,
        `Doctor Note: ${this.docNoteRequired}`,
        `Reason(s) & Current Medications: ${this.appointmentDetails.reasonForVisit}`,
        `Has had annual checkup?: ${this.annualCheckup}`,
        `Specialist requested: ${this.seeSpecialist}`,
      ];
      return appDetails;
    },
  },
  methods: {
    onNextClick() {
      this.$store.commit('addDefaultPatientNotes', {
        basicNotes: [...this.appointmentDetailsNotes],
      });
      this.$store.commit('addOtherPatientNote', {
        newNoteText: `Reason for visit: ${this.appointmentDetails.reasonForVisit}`,
        noteTags: ['Primary Complaint'],
      });
      /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
  },
  mounted() {
    this.$store.commit('setCompleted', this.completed);
    this.$store.commit('setOnNextClick', this.onNextClick);
  },
  beforeRouteLeave(to, from, next) {
    // if we're going back, no need to check we have data
    if (
      to.name === this.$store.getters.previousFlowRoute(this.$route.name || to.name === 'Login') ||
      to.name === 'RegisterGateway'
    ) {
      next();
    }
    // check to ensure data was correctly logged to store
    const { patientData } = this.$store.state.registration;
    const { registration } = this.$store.state;
    if (patientData.notes && registration.appointmentReasons) {
      next();
    }
  },
  watch: {
    completed(val) {
      this.$store.commit('setCompleted', val);
    },
  },
};
</script>
