import { render, staticRenderFns } from "./RegisterInformAbc.vue?vue&type=template&id=165b47d0&scoped=true"
import script from "./RegisterInformAbc.vue?vue&type=script&lang=js"
export * from "./RegisterInformAbc.vue?vue&type=script&lang=js"
import style0 from "./RegisterInformAbc.vue?vue&type=style&index=0&id=165b47d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165b47d0",
  null
  
)

export default component.exports